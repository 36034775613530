import groq from 'groq'
import qs from 'qs'
import {z} from 'zod'

import {getFeaturedJobs} from '../../core/api'
import {backendClient} from '../../core/sanity'
import {generalResourceQuery} from '../resources/queries'
import {
  getIndexPageQuery,
  getRoleFilters,
  getUniqueAuthors,
  indexPageGROQQuery,
} from './queries'
import {ResourceFeedFilterProjectionsSchema} from './types'

export async function getIndexStaticProps(
  field: 'Index' | 'Optometry' | 'Ophthalmology' | 'Interventional Mindset',
) {
  const roleFilterString = getRoleFilters(field)
  const query = getIndexPageQuery(field, roleFilterString)

  const {indexPage, resources} = await backendClient.fetch(query)
  const featuredAuthors = await getUniqueAuthors(roleFilterString)

  let featuredJobs = []

  if (indexPage.displayFeaturedJobs) {
    try {
      featuredJobs = await getFeaturedJobs({
        field,
        featuredJobsTag: indexPage.featuredJobsTag,
      })
    } catch (e) {
      // continue without featured jobs
    }
  }

  if (!indexPage) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      field: field !== 'Index' ? field : null,
      indexPage,
      resources,
      featuredAuthors,
      featuredJobs,
    },
  }
}

export async function getCustomIndexStaticProps(
  field: string,
  resourcesFilter: string,
) {
  const query = groq`{
    "indexPage": ${indexPageGROQQuery(field)},
    "resources": {
      "recentResources": ${generalResourceQuery(resourcesFilter, 9)}
    }
  }`

  const {indexPage, resources} = await backendClient.fetch(query)

  if (!indexPage) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      field,
      indexPage,
      resources,
    },
  }
}

export function getFieldFilter(field: string) {
  switch (field) {
    case 'Optometry':
      return 'roles=Optometrist'
    case 'Ophthalmology':
      return 'roles=Ophthalmologist'
    case 'Interventional Mindset':
      return 'tags=Interventional+Mindset'
    default:
      return ''
  }
}

/**
 * Format the resource feed filters into a usable format for qs.stringify
 * There could be multiple values with the same property e.g. Glaucoma with Clinical Specialty and Category
 * So create an object with the key being the filter type and the value being an array of all the filter values
 * @param filters
 */
export function getResourceFeedQueryString(
  filters: z.infer<typeof ResourceFeedFilterProjectionsSchema>,
) {
  //
  const combinedQs = filters.reduce(
    (acc: Record<string, string[]>, filter: {qs: Record<string, string>}) => {
      Object.keys(filter.qs).forEach((key) => {
        // If the key already exists, add the new value to the array
        if (acc[key]) {
          acc[key] = [...acc[key], filter.qs[key]]
        } else {
          acc[key] = [filter.qs[key]]
        }
      })
      return acc
    },
    {},
  )
  return qs.stringify(combinedQs, {
    addQueryPrefix: true,
    encodeValuesOnly: true,
    format: 'RFC1738',
    arrayFormat: 'repeat',
  })
}
