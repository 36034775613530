/* eslint-disable import/prefer-default-export */
import groq from 'groq'

import {sanity} from '../../core/sanity'
import {NPIFormProps} from '../sanity/NPIForm'

const imageProjection = groq`
  _type == "linkableImage" => {
    ...,
    "dimensions": {
      "width": image.asset->metadata.dimensions.width,
      "height": image.asset->metadata.dimensions.height,
      "aspectRatio": image.asset->metadata.dimensions.aspectRatio,
    }
  }
`

const bodyProjection = groq`
  _type == "gatedContent" => {
    ...,
    body[] {
      ...,
      _type == 'pdfEmbed' => {
        ...,
        "pdfUrl": @.asset->url
      }
    }
  },
  _type == "reference" => {
    "unfurledRef": @->
  },
  _type == "pdfEmbed" => {
    ...,
    "pdfUrl": @.asset->url
  },
  _type == "lottieFile" => {
    ...,
    "lottieUrl": lottieFile.asset->url
  },
  ${imageProjection}
`

export const resourceProjection = groq`
{
  _createdAt,
  _id,
  _rev,
  _type,
  _updatedAt,
  authors[]->{_id, name, 'image': profilePicture, bio, "slug": slug.current, socialLinks},
  body[]{
    ...,
    ${bodyProjection},
    _type == "flashcards" => {
      flashcards[]{
        question[]{
          ...,
          ${imageProjection}
        },
        answer[]{
          ...,
          ${imageProjection}
        }
      }
    },
    _type == "lottieContentBlock" => {
      ...,
      "lottieUrl": lottieFile.asset->url
    },
    _type == "twoColumnContentBlock" => {
      ...,
      content[] {
       ...,
       ${bodyProjection}
      }
    },
    markDefs[]{
      ...,
      _type == "internalLink" => {
        "slug": @.resource->slug.current
      }
    }
  },
  "category": category->name,
  citations,
  "clinicalSpecialty": clinicalSpecialty->name,
  containsGatedContent,
  containsLottie,
  customAd->{
    name,
    headerCode,
    ctaWidgetCode,
    inReadCode,
    sidebarCode,
    stickyFooterCode
  },
  customAdsEndDateTime,
  customSponsors->{
    name,
    sponsors[]->{name, image, website}
  },
  "disclosure": select(
    disclosure == "default" => "This is editorially independent content",
    disclosure == "advertising" => "This is editorially independent content supported by advertising",
    disclosure == "sponsor" => "This post is sponsored by " + sponsor->name,
    disclosure == "sponsorAdvertising" => "This is editorially independent content supported by advertising from " + sponsor->name,
  ),
  disclosureOverride,
  enableInfiniteScroll,
  enableTextToSpeech,
  "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
  "featuredImage": featuredImage{
    _type,
    asset{
      _ref,
      _type
    },
    "dimensions": {
      "width": asset->metadata.dimensions.width,
      "height": asset->metadata.dimensions.height,
      "aspectRatio": asset->metadata.dimensions.aspectRatio,
    }
  },
  featuredText,
  gatedContentEmoji,
  gatedContentHeading,
  gatedContentSubheading,
  gatedContentShowSubscriberCount,
  gatedContentOptInLabel,
  inReadAdIteration,
  includeCampaignTracker,
  "mediaType": mediaType->name,
  publishedAt,
  roles,
  showAds,
  showCTAWidget,
  showETR,
  showFeaturedImage,
  showInReadAds,
  showSocialButtons,
  showSponsorsBlock,
  "slug": slug.current,
  "sponsor": sponsor->{uuid, name, "slug": slug.current},
  "stickyFooter": stickyFooter->{stickyFooterCTA, stickyFooterContent},
  "tags": tags[]->name,
  templateType,
  title, 
  titleOverride,
  "infiniteScrollSlugs": infiniteScrollResources[]->slug.current,
  useCustomAds,
}
`

export const getPublishedResourceQuery = groq`*[
  _type == "resourcePage" 
  && !(_id in path("drafts.**"))
  && slug.current == $slug
][0] ${resourceProjection}
`

export const getResourceDraftQuery = groq`*[
  _type == "resourcePage" 
  && (_id in path("drafts.**"))
  && _id == $_id
][0] ${resourceProjection}
`

export const basicResourceProjection = groq`{
  _id,
  _type,
  authors[]->{name, "slug": slug.current},
  "category": category->name,
  featuredImage,
  featuredText,
  publishedAt,
  "slug": slug.current,
  sponsor->{name, uuid},
  "tags": tags[]->name,
  title,
  titleOverride,
  "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
}`

export const generalResourceQuery = (filterString: string, items: number) =>
  groq`*[
    _type == "resourcePage" 
    && !(_id in path("drafts.**")) ${filterString}
  ] | order(publishedAt desc)[0...${items}]${basicResourceProjection}`

export interface SitemapDataProjection {
  authors: {
    slug: string
    updatedAt: string
  }[]
  resources: {
    slug: string
    updatedAt: string
  }[]
  categories: {
    category: string
  }[]
  clinicalSpecialties: {
    clinical_specialty: string
  }[]
  mediaTypes: {
    media_type: string
  }[]
}

export const getSitemapDataQuery = groq`{
  "authors": *[
    _type == "author"
    && defined(slug)
  ]{
    "slug": slug.current,
    "updatedAt": _updatedAt
  },
  "resources": *[
    _type == "resourcePage"
    && !(_id in path("drafts.**"))
  ]{
    "slug": slug.current,
    "updatedAt": _updatedAt
  },
  "categories": *[
    _type == "category" 
    && defined(name)
  ]{
    "category": name
  },
  "clinicalSpecialties": *[
    _type == "clinicalSpecialty" 
    && defined(name)
  ]{
    "clinical_specialty": name
  },
  "mediaTypes": *[
    _type == "mediaType" 
    && defined(name)
  ]{
    "media_type": name
  },
}
`

export function getDocumentsByIds(ids: string[]) {
  const query = `*[_id in $ids] {
    _id,
    _type,
    featuredImage,
    "resourceCategory": category->name,
    "storyCategory": category,
    title,
    titleOverride,
    publishedAt,
    dateSlug,
    "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
    "slug": slug.current,
    "authors": authors[]->{name},
  } | order(publishedAt desc)`
  return sanity.fetch(query, {ids})
}

export async function getNPIForm() {
  const query = `*[_type == "npiForm"] 
  {
    ...,
    "image": {
      ...image,
      "dimensions": {
        "width": image.asset->metadata.dimensions.width,
        "height": image.asset->metadata.dimensions.height,
        "aspectRatio": image.asset->metadata.dimensions.aspectRatio,
      }
    }
  } [0]`
  return await sanity.fetch<NPIFormProps>(query)
}
